import * as THREE from 'three'
import EventDispatcher from '../common/EventDispatcher'
import { Global } from '../common/global';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader'


export default class AssetLoader{
    constructor(scene){
        this.scene=scene;
    }
    init(){
        this.events= EventDispatcher.getObj();
        this.baseURL= './',//'https://d2xrkn56aw2rdo.cloudfront.net/SpaceGyro2';
        this.loadingMgr= new THREE.LoadingManager(
            ()=>{
                this.events.dispatchEvent({ type: "LOADER_EVENTS", message: {
                    "event_type":"load_complete",
                    data:{
        
                    }
                }});
                this.events.dispatchEvent({ type: "GAMESCENE_EVENTS", message: {
                    "event_type":"add_sky",
                    data:{
        
                    }
                }});
            },
            ()=>{
            },
            ()=>{
            }
        );

    
        this.textureLoader = new THREE.TextureLoader(this.loadingMgr);
        this.textureLoader.setPath(this.baseURL);

        this.audioLoader = new THREE.AudioLoader(this.loadingMgr);
        this.audioLoader.setPath(this.baseURL);
        
        this.cubeTextureLoader = new THREE.CubeTextureLoader(this.loadingMgr);
        this.cubeTextureLoader.setPath(this.baseURL);

        this.gltfLoader= new GLTFLoader(this.loadingMgr);
        this.gltfLoader.setPath(this.baseURL);
        this.dracoLoader = new DRACOLoader();
        this.dracoLoader.setDecoderPath( '/examples/jsm/libs/draco/' );
        this.gltfLoader.setDRACOLoader( this.dracoLoader );
    }
    load(){
        let assetsToLoad= Global.assets;
        Object.keys(assetsToLoad).forEach((typeKey)=>{
            let itemKeys= Object.keys(assetsToLoad[typeKey]);
            itemKeys.forEach((itemKey)=>{
                if(typeKey == 'textures'){
                    this[assetsToLoad[typeKey][itemKey]['key']]= this.textureLoader.load(assetsToLoad[typeKey][itemKey]['path']);
                    Global.assets[typeKey][itemKey]['asset']=this[assetsToLoad[typeKey][itemKey]['key']]
                }
                if(typeKey == 'cubemap'){
                    this[assetsToLoad[typeKey][itemKey]['key']]= this.cubeTextureLoader.load(assetsToLoad[typeKey][itemKey]['path']);
                    Global.assets[typeKey][itemKey]['asset']=this[assetsToLoad[typeKey][itemKey]['key']]
                }
                if(typeKey == 'glbs'){
                    this[assetsToLoad[typeKey][itemKey]['key']]= this.gltfLoader.load(assetsToLoad[typeKey][itemKey]['path'][0],(gltf)=>{
                        Global.assets[typeKey][itemKey]['asset']=gltf;
                    });
                }
                /* if(typeKey == "sounds"){
                    let x=this.audioLoader.load(assetsToLoad[typeKey][itemKey]['path'],function(typeKey, itemKey, buffer){
                        Global.assets[typeKey][itemKey]['asset']=buffer;
                        console.log(buffer,'BGSound')
                    }.bind(this, typeKey, itemKey));
                    console.log(x,'xxxx')
                    
                } */
        
            })
            // console.log(itemKeys,'itemKeys')
            // if(typeKey == 'cubemap')
        });
        /* this.environmentMapTexture = this.cubeTextureLoader.load([
            '/textures/environmentMaps/0/px.png',
            '/textures/environmentMaps/0/nx.png',
            '/textures/environmentMaps/0/py.png',
            '/textures/environmentMaps/0/ny.png',
            '/textures/environmentMaps/0/pz.png',
            '/textures/environmentMaps/0/nz.png'
        ]);
        console.log(this.environmentMapTexture) */
    }
}