import * as THREE from 'three'
import Camera from './components/Camera'
import EventDispatcher from './common/EventDispatcher'
import GUI from './components/GUI'
import AssetLoader from './components/AssetLoader';
import {
    Global
} from './common/global';
import {isMobile} from 'mobile-device-detect';
import Room from './components/Room';

import './scss/style.scss';
import Control from './components/Control';
import {
  Server
} from './common/callServer';

export default class Home extends THREE.Scene {
    environmentMap=null;
    sizes = {
        width: window.innerWidth,
        height: window.innerHeight
    }

  
    constructor() {
        super();

        window.addEventListener('resize', this.onResize.bind(this))

    }
    init() {
        
 

        this.events = EventDispatcher.getObj();
        Global.events= this.events;
        this.events.addEventListener("LOADER_EVENTS", this.onEvents.bind(this));
        this.events.addEventListener("GAME_EVENTS", this.onGameEvents.bind(this));

        this.canvas = document.querySelector('canvas.webgl');

        this.clock = new THREE.Clock();

        this.gui = new GUI();
        this.gui.init();

        this.camera = new Camera(45, this.sizes.width / this.sizes.height, 0.025, 5);
        this.camera.init();
        this.add(this.camera);

        // this.controls= new Control(this.camera, this.canvas);
        // this.controls.init();

   

   



        this.renderer = new THREE.WebGLRenderer({
            canvas: this.canvas,
            antialias : false,
            precision:'highp',
            // stencil:false,
            // alpha:true,
            powerPreference:'high-performance'//low-power
        });
        // this.renderer.autoClear=false;
        this.renderer.physicallyCorrectLights = true;
        this.renderer.toneMappingExposure = 1.;
        this.renderer.toneMapping = THREE.LinearToneMapping;
        this.renderer.gammaOutput = true;
        this.renderer.outputColorSpace = THREE.SRGBColorSpace;

        this.renderer.gammaFactor = 2.2
        this.renderer.setClearColor(0x000000, 0);
        this.renderer.useLegacyLights = false;
        // this.renderer.expo
        this.renderer.setSize(this.sizes.width * 1, this.sizes.height * 1)
        this.renderer.setPixelRatio(Math.min(Math.max(2, window.devicePixelRatio), isMobile ? 2.5 : 2.5));

        // this.postProcessing=new PostProcessing(this, this.renderer, this.camera);
        // this.postProcessing.init(this.sizes);

        this.assetLoader = new AssetLoader(this);
        this.assetLoader.init();
        this.assetLoader.load();

        this.room= new Room(this, this.renderer);
        this.room.init();

        this.tick();

    }


    onEvents(data) {
        switch (data['message']['event_type']) {
            case "load_complete":
                this.openRoom();
                break;
        }
    }
    onGameEvents(data){
        switch (data['message']['event_type']) {
            case "replay_game":
                this.replayGame();
                break;
        }
    }
    openRoom() {
        this.environmentMap = Global.assets['cubemap']['0']['asset'];
        this.environmentMap.encoding = THREE.sRGBEncoding;

        // this.background = this.environmentMap;
        // this.background.rotation=3.14;
        // this.backgroundIntensity = 1;
        
        this.events.dispatchEvent({
            type: "GAMESCENE_EVENTS",
            message: {
                "event_type": "create_scene",
                data: {

                }
            }
        });
        this.events.dispatchEvent({
            type: "PLAYER_EVENTS",
            message: {
                "event_type": "add_spaceship",
                data: {

                }
            }
        });
    }
    replayGame(){
        this.initDirectionsSaved= false;
     
    }
   
  
    onResize() {
        // Update sizes
        this.sizes.width = window.innerWidth
        this.sizes.height = window.innerHeight
        this.events.dispatchEvent({
            type: "CAMERA_EVENTS",
            message: {
                "event_type": "resize",
                data: {
                    "aspect": this.sizes.width / this.sizes.height,
                    "sizes": this.sizes

                }
            }
        });
        this.renderer.setSize(this.sizes.width, this.sizes.height)
        this.renderer.setPixelRatio(Math.min(Math.max(2, window.devicePixelRatio), isMobile?2.5:2.5))
    }
   
    update(now, delta){
        this.renderer.render(this, this.camera);

    }
    tick(now) {

        const elapsedTime= this.clock.getElapsedTime();

        
        this.events.dispatchEvent({
            type: "GAMESCENE_EVENTS",
            message: {
                "event_type": "update",
                data: {
                    elapsedTime
                }
            }
        });

        this.renderer.render(this, this.camera);
   

        window.requestAnimationFrame(this.tick.bind(this))
       
    }
  
}





let merchandiseIndex = 0;
let merchandiseTO = null;
let codeRevealed = false;
let countrySelected=null;
let errorTO= null;
let introPlayed=false;


const gotoMerchandise = (indexSelected) => {
  merchandiseIndex = indexSelected;
}

const showNextMerchandise = (index = -1) => {

  merchandiseTO && clearInterval(merchandiseTO);
  if (index == -1) {
    merchandiseIndex++;
  } else {
    merchandiseIndex = index;
  }

  if (merchandiseIndex > 7) {
    merchandiseIndex = 1;
  }

  document.querySelectorAll(".merchandise_sec .navs .nav").forEach((nav) => {
    nav.classList.remove('active');
  });
  document.querySelector(`.merchandise_sec .navs .nav:nth-child(${merchandiseIndex})`).classList.add('active');
  gsap.to(".merchandises", {
    translateX: `-${(merchandiseIndex-1)*150}%`,
    ease: "expo.out",
    duration: 0.5,
    yoyo: false
  });

  merchandiseTO = setTimeout(showNextMerchandise, 4000);
}
const activateMerchandiseCarousel = () => {
  document.querySelectorAll(".merchandise_sec .navs .nav").forEach((nav, index) => {
    nav.addEventListener("click", showNextMerchandise.bind(this, index + 1))
  })
  showNextMerchandise();
};

const showCountrySelection = () => {

  document.querySelector(".country_selection").classList.add('active');
  document.querySelector(".country_selection .center_logo video").addEventListener('ended', () => {
    introPlayed=true;
    document.querySelector(".country_selection .logo_pulse1").classList.add('active');
    document.querySelector(".country_selection .logo_pulse2").classList.add('active');




    document.querySelector(".country_selection .center_logo video").classList.remove('active');
    document.querySelector(".country_selection .center_logo img").classList.add('active');
    
    animateCountryItems();
    setTimeout(() => {
      document.querySelector(".country_selection .selection_content").classList.add('active');
      document.querySelector(".country_selection .country_selection_header").classList.add('active');
      document.querySelector(".country_selection .country_selection_footer").classList.add('active');
    }, 500);
    setTimeout(() => {
      document.querySelector(".country_selection .oreo_country_bottle").classList.add('active');
    }, 750);
    setTimeout(() => {
      document.querySelector(".country_selection .oreo_country_cookie").classList.add('active');

    }, 1000);
  });
  // document.querySelector(".country_selection .center_logo video").src='UI/logoAnim.mp4';
  // document.querySelector(".country_selection .center_logo video").preload = 'auto';
  document.querySelector(".country_selection .center_logo video").load();
  // document.querySelector(".country_selection .center_logo video").autoplay= true;
  // document.querySelector(".country_selection .center_logo video").muted= true;
 
  document.querySelector(".country_selection .center_logo video").play();
  setTimeout(()=> {
    if(!introPlayed){
      document.querySelector(".country_selection .center_logo video").currentTime= 1.44;
    }
    // 
  }, 1600)

}
const showRegistration= () => {
  if(document.querySelector(".user_register").classList.contains('active')) return false;

  // Global.serverObj.send(Global.URL_VUPDATE, null, null, {
  //   'saveType': 'standToWinExclusiveClick',
  //   'uniqID': Global.U_ID
  // });
  Global.serverObj.send(Global.URL_VUPDATE, null, null, {
    'saveType': 'showRegistration_click',
    'uniqID': Global.U_ID
  });
  document.querySelector(".user_register").classList.add('active');
  document.querySelector(".user_register .logo_pulse1").classList.add('active');
    document.querySelector(".user_register .logo_pulse2").classList.add('active');
    document.querySelector(".user_register .center_logo img").classList.add('active');

    setTimeout(animateRegisterItems.bind(this,''), 250);
    setTimeout(() => {
      document.querySelector(".user_register .oreo_register_bottle").classList.add('active');
    }, 750);
    setTimeout(() => {
      document.querySelector(".user_register .oreo_register_cookie").classList.add('active');

    }, 1000);

  setTimeout(() => {

    document.querySelector(".user_register .user_register_header").classList.add('active');
    document.querySelector(".user_register .user_register_footer").classList.add('active');
    if(codeRevealed){
      showCoupon();
    }else{
      document.querySelector(".user_register .register_content").classList.add('active');
    }
  }, 500);
}
const showLetsgoScreen= () => {
  document.querySelector(".poster_banner_section").style.display='block'
  Global.serverObj.send(Global.URL_VUPDATE, null, null, {
    'saveType': 'unlockPoster_click',
    'uniqID': Global.U_ID
  });
setTimeout(() => {
  if(document.querySelector(".poster_pages").classList.contains('active')) return false;
  if(document.querySelector(".poster_banner_section").classList.contains('active')) return false;
  Global.serverObj.send(Global.URL_VUPDATE, null, null, {
    'saveType': 'standToWinExclusiveClick',
    'uniqID': Global.U_ID
  });
  document.querySelector(".poster_pages").classList.add('active') ;
  setTimeout(animateBannerItems.bind(this,"poster_"), 200)
  setTimeout(() => {
    document.querySelector("#posterLetsGo").classList.add('active')
  }, 1500);
 
  document.querySelector(".poster_banner_section").classList.add('active');
}, 50);
  // document.querySelector(".poster_banner_section .logo_pulse1").classList.add('active');
  //   document.querySelector(".poster_banner_section .logo_pulse2").classList.add('active');
  //   document.querySelector(".poster_banner_section .center_logo img").classList.add('active');


    // setTimeout(animateRegisterItems, 250);
    // setTimeout(() => {
    //   document.querySelector(".poster_banner_section .oreo_register_bottle").classList.add('active');
    // }, 750);
    // setTimeout(() => {
    //   document.querySelector(".poster_banner_section .oreo_register_cookie").classList.add('active');

    // }, 1000);

  setTimeout(() => {

    // document.querySelector(".poster_banner .poster_banner").classList.add('active');
    // document.querySelector(".poster_banner .user_register_footer").classList.add('active');
    // if(codeRevealed){
    //   showCoupon();
    // }else{
    //   document.querySelector(".user_register .register_content").classList.add('active');
    // }
  }, 500);
}
const onLetsGoClick= () => {
  $('.posterPage').removeClass('active')
  $('.posterPage').css('display','none')
  document.querySelector(".poster_user_register").style.display='block';
document.querySelector('.poster_code').innerHTML=countryCodes[countrySelected]
  Global.serverObj.send(Global.URL_VUPDATE, null, null, {
    'saveType': 'letsGo_click',
    'uniqID': Global.U_ID
  });
  setTimeout(() => {
    document.querySelector(".poster_user_register").classList.add('active');
    setTimeout(animateBannerItems.bind(this,"poster_"), 200)
    setTimeout(animateRegisterItems.bind(this,"poster_"), 250)
    document.querySelector(`.poster_user_register .label2`).classList.add('active');

  document.querySelector(".poster_user_register .logo_pulse1").classList.add('active');
    document.querySelector(".poster_user_register .logo_pulse2").classList.add('active');
    document.querySelector(".poster_user_register .center_logo img").classList.add('active');
    document.querySelector(".poster_user_register .register_content").classList.add('active');

    setTimeout(() => {
      document.querySelector(".poster_user_register .oreo_register_bottle").classList.add('active');
    }, 750);
    setTimeout(() => {
      document.querySelector(".poster_user_register .oreo_register_cookie").classList.add('active');

    }, 1000);

  }, 500);

}
const hideCountrySelection= () => {
  document.querySelector(".country_selection .country_selection_header").classList.remove('active');
  document.querySelector(".country_selection .country_selection_footer").classList.remove('active');
  setTimeout(() => {
    document.querySelector(".country_selection").classList.remove('active');
  }, 250)
  setTimeout(() => {
    document.querySelector(".country_selection").style.display="none"
  }, 1000)
}
const animateBannerItems= (pre) => {
  document.querySelector(`.${pre}banner_section`).classList.add('active')
  document.querySelector(`.${pre}banner_section .center_logo`).classList.add('active');
  setTimeout(() => {
    document.querySelector(`.${pre}banner_section>.logo_pulse1`).classList.add('active');
    document.querySelector(`.${pre}banner_section>.logo_pulse2`).classList.add('active');
    setTimeout(() => {
      document.querySelector(`.${pre}banner_section .pack`).classList.add('active');
     

    }, 250)
    setTimeout(()=> {
      document.querySelector(`.${pre}banner_section .c1`).classList.add('active');
      document.querySelector(`.${pre}banner_section .c2`).classList.add('active');
      document.querySelector(`.${pre}banner_section .c3`).classList.add('active');
      document.querySelector(`.${pre}banner_section .c4`).classList.add('active');
      document.querySelector(`.${pre}banner_section .label`).classList.add('active');
      document.querySelector(`.${pre}banner_section .label2`) && document.querySelector(`.${pre}banner_section .label2`).classList.add('active');
    }, 500);
    setTimeout(() => {
      document.querySelector(".scroll_hint").classList.add('active');
    }, 1000)
    gsap.to(`.${pre}banner_section .pack`, {
      scale: 1,
      duration: 0.5,
      ease: "elastic.out(1,0.1)",
      yoyo: false,
      repeat: 0,
      delay: 0.25
      // repeatDelay:1,
    });
    gsap.to(`.${pre}banner_section .c1`, {
      rotate: 0,
      x:'+=6%',
      y:'-=64%',
      duration: 1,
      ease: "elastic.out(1,0.5)",
      delay: 0.55
    });
    gsap.to(`.${pre}banner_section .c2`, {
      rotate: 0,
      x:'-=25%',
      y:'-=50%',
      duration: 1,
      ease: "elastic.out(1,0.5)",
      delay: 0.65
    });
    gsap.to(`.${pre}banner_section .c3`, {
      scale: 1,
      duration: 0.5,
      ease: "elastic.out(1,0.5)",
      delay: 0.6
    });
    gsap.to(`.${pre}banner_section .c4`, {
      scale: 1,
      duration: 0.5,
      ease: "elastic.out(1,0.5)",
      delay: 0.75
    });
    gsap.to(`.${pre}banner_section .c4`, {
      rotate: 360*2,
      duration: 2,
      ease: "elastic.out(1,1.5)",
      delay: 0.6
    });

    gsap.to(`.${pre}banner_section>.center_logo`, {
      scale: 1.1,
      duration: 1,
      ease: "elastic.out(1,0.1)",
      yoyo: false,
      repeat: -1,
      repeatDelay: 6,
    });
    gsap.to(`.${pre}banner_section>.logo_pulse1`, {
      scale: 8,
      duration: 1,
      opacity: 0,
      ease: "power3.out",
      yoyo: false,
      repeat: -1,
      repeatDelay: 6,
    });
    gsap.to(`.${pre}banner_section>.logo_pulse2`, {
      scale: 8,
      duration: 2,
      delay: 0.2,
      opacity: 0,
      ease: "power3.out",
      yoyo: false,
      repeat: -1,
      repeatDelay: 5,
    });
  }, 500)

  
}
const animateRegisterItems= (pre) => {
  gsap.to(`.${pre}user_register .center_logo`, {
    scale: 1.1,
    duration: 1,
    ease: "elastic.out(1,0.1)",
    yoyo: false,
    repeat: -1,
    repeatDelay: 6,
  });
  gsap.to(`.${pre}user_register .logo_pulse1`, {
    scale: 8,
    duration: 1,
    opacity: 0,
    ease: "power3.out",
    yoyo: false,
    repeat: -1,
    repeatDelay: 6,
  });
  gsap.to(`.${pre}user_register .logo_pulse2`, {
    scale: 8,
    duration: 2,
    delay: 0.2,
    opacity: 0,
    ease: "power3.out",
    yoyo: false,
    repeat: -1,
    repeatDelay: 5,
  });
}
const animateCountryItems= () => {
  gsap.to(".country_selection .center_logo", {
    scale: 1.1,
    duration: 1,
    ease: "elastic.out(1,0.1)",
    yoyo: false,
    repeat: -1,
    repeatDelay: 6,
  });
  gsap.to(".country_selection .logo_pulse1", {
    scale: 8,
    duration: 1,
    opacity: 0,
    ease: "power3.out",
    yoyo: false,
    repeat: -1,
    repeatDelay: 6,
  });
  gsap.to(".country_selection .logo_pulse2", {
    scale: 8,
    duration: 2,
    delay: 0.2,
    opacity: 0,
    ease: "power3.out",
    yoyo: false,
    repeat: -1,
    repeatDelay: 5,
  });
}
const animateItems= (cls) => {
  gsap.to(`.${cls} .center_logo`, {
    scale: 1.1,
    duration: 1,
    ease: "elastic.out(1,0.1)",
    yoyo: false,
    repeat: -1,
    repeatDelay: 6,
  });
  gsap.to(`.${cls} .logo_pulse1`, {
    scale: 8,
    duration: 1,
    opacity: 0,
    ease: "power3.out",
    yoyo: false,
    repeat: -1,
    repeatDelay: 6,
  });
  gsap.to(`.${cls} .logo_pulse2`, {
    scale: 8,
    duration: 2,
    delay: 0.2,
    opacity: 0,
    ease: "power3.out",
    yoyo: false,
    repeat: -1,
    repeatDelay: 5,
  });
}

let countryProceed;
let buyNowBtn;
let countrySelect;
let spotifyUnlock;
let viewMerchandise;
let letsGo;
let momentNext;
let uploadNext;
let generateTrigger;
let bestieName;
let uploadType;
let downloadBtn;
let retryBtn;
let shareBtn;
let messageforBestie;
let poster_pages;
let videoPlay;
let videoToPlay;
let registerSubmit;
let posterRegisterSubmit;
let prizeCloseBtn;
let poster_prizeCloseBtn;
let TCBtn= null;
let poster_TCBtn= null;
let promotionBtn= null;
let poster_promotionBtn= null;
let AgeChecked= false;
let TCChecked= false;
let poster_TCChecked= false;
let poster_promotionChecked= false;
let promotionChecked= false;
let registerUserName;
let slectedMoment;
let slectedImage;
let registerEmail;
let curPage;
let registerMobile;
let poster_registerUserName;
let poster_registerEmail;
let poster_registerMobile;
let poster_dob;
let dob;
let registerActive= true;
let AgeBtn= null;
let poster_AgeBtn= null;
let prizeValid=null;
let prizeMap={
  "Grand Prize": {
    'title': 'CONGRATULATIONS! YOU HAVE WON THE LIMITED EDITION BESTIE GOODIE PACK FOR YOU & YOUR BESTIE',
    'prize': 'grand-prize'
  },
  "Caps":{
    'title': 'CONGRATULATIONS! YOU HAVE WON A LIMITED EDITION CAP',
    'prize': 'prize3'
  },
  "Backpack":{
    'title': 'CONGRATULATIONS! YOU HAVE WON A LIMITED EDITION BACKPACK',
    'prize': 'prize1'
  },
  "T-Shirt":{
    'title': 'CONGRATULATIONS! YOU HAVE WON A LIMITED EDITION T-SHIRT',
    'prize': 'prize2'
  },
  "Spotify Voucher":{
    'title': 'CONGRATULATIONS! YOU HAVE WON A SPOTIFY VOUCHER',
    'prize': 'prize4'
  }
}






let countryCodes={
  'morocco':'+212',
  'southafrica':'+27',
  'kenya':'+254',
  'mauritius':'+230',
  'zambia':'+260',
  'mozambique': '+258',
  'tanzania': '+255',
  'nigeria': '+234',
  'namibia': '+264',
  'botswana': '+267',
  'swaziland': '+268',
  'cameroon': '+237',
  'ivorycoast': '+225',
  'malawi': '+265',
  'seychelles': '+248',
  'drc': '+243',
  'algeria': '+213',
  'angola': '+244'
}


let spotifyEnabledCountries={
  'southafrica': 'https://open.spotify.com/presents/coke-oreo-bestie-mode/EN-ZA',
  'kenya': 'https://open.spotify.com/presents/coke-oreo-bestie-mode/EN-KE',
  'tanzania':'open.spotify.com/presents/coke-oreo-bestie-mode/EN-TZ'
};
const activateItems= () => {

  buyNowBtn= document.querySelector('.buynow_sec #buyNow');
  countryProceed= document.querySelector("#countryProceed");
  countrySelect= document.querySelector("#country_select");
  spotifyUnlock= document.querySelector("#spotify_unlock");

  viewMerchandise= document.querySelector(".win_merchandise");
  TCBtn= document.querySelector("#t_c_check");
  poster_TCBtn= document.querySelector("#poster_t_c_check");
  // AgeBtn= document.querySelector('#t_c_age');
  // poster_AgeBtn= document.querySelector('#poster_t_c_age');
  promotionBtn= document.querySelector("#t_c_subscribe");
  poster_promotionBtn= document.querySelector("#poster_t_c_subscribe");
  

  poster_pages= document.querySelector("#unlockPoster");
  letsGo= document.querySelector("#posterLetsGo");
  momentNext = document.querySelector("#momentNext");
  uploadNext = document.querySelector("#uploadNext");
  generateTrigger = document.querySelector("#generateTrigger");

  // videoPlay= document.querySelector("#videoPlay");
  // videoToPlay= document.querySelector(".video_sec video");
  registerSubmit= document.querySelector("#registerSubmit");
  posterRegisterSubmit= document.querySelector("#posterRegisterSubmit");
  prizeCloseBtn= document.querySelector("#closeBtn");
  poster_prizeCloseBtn= document.querySelector("#poster_closeBtn");

  countryProceed.addEventListener("click", checkIfCountrySelected);
  countrySelect.addEventListener("change", saveCountry);
  spotifyUnlock.addEventListener("click", unlockSpotify);
  viewMerchandise.addEventListener("click", showRegistration);
  letsGo.addEventListener("click", onLetsGoClick);
  poster_pages.addEventListener("click", showLetsgoScreen);
  momentNext.addEventListener("click", onClickoMentNext);
  uploadNext.addEventListener("click", onClickUploadNext);
  generateTrigger.addEventListener("click", onClickGenerateTrigger);

  // videoPlay.addEventListener("click", playOrPlauseVideo);
  // videoToPlay.addEventListener("click", playOrPlauseVideo);
  registerSubmit.addEventListener("click", checkUserRegistration);
  posterRegisterSubmit.addEventListener("click", poster_checkUserRegistration);
  prizeCloseBtn.addEventListener("click", closePrize);
  poster_prizeCloseBtn.addEventListener("click", poster_closePrize);
  buyNowBtn.addEventListener("click", onBuyNowClick);
  
  TCBtn.addEventListener("click", updateTCStatus);
  // AgeBtn.addEventListener("click", updateAgeStatus);
  promotionBtn.addEventListener("click", updatePromotionStatus);
  poster_TCBtn.addEventListener("click", poster_updateTCStatus);
  // poster_AgeBtn.addEventListener("click", poster_updateAgeStatus);
  poster_promotionBtn.addEventListener("click", poster_updatePromotionStatus);

  downloadBtn=document.querySelector(".download");
  retryBtn=document.querySelector(".retry");
  shareBtn=document.querySelector(".share");
 

  downloadBtn.addEventListener("click", poster_download);
  retryBtn.addEventListener("click", poster_retry);
  shareBtn.addEventListener("click", poster_share);


  document.getElementById('UploadedImage').addEventListener('change', function() {
    const fileInput = document.getElementById('UploadedImage');
    const label = document.querySelector('#uploadStatus');
console.log(fileInput.files && fileInput.files[0]);

    if (fileInput.files && fileInput.files[0]) {
        document.querySelector('.preview').src=URL.createObjectURL(fileInput.files[0])
        document.querySelector('.preview').classList.add('active')
        document.querySelector('.reupload').classList.add('active')
        label.innerHTML = '';  // Change label to "Uploaded" when file is selected
    } else {

        label.innerHTML = 'Upload Picture';  // Reset label if no file is selected
    }
});
// Get all the message elements
const messages = document.querySelectorAll('.message');

// Get the textarea
const textarea = document.getElementById('messageforBestie');

// Add click event to each message
messages.forEach(message => {
    message.addEventListener('click', function() {
        // Set the textarea value to the clicked message's trimmed text
        textarea.value = this.textContent.trim();
    });
});

document.querySelector('#back').addEventListener('click',() => {
  if(curPage=='moment_upload'){
    pageTransition('moment_selection')
  }else if(curPage=='moment_details'){   
     pageTransition('moment_upload')
  }else if(curPage=='moment_details'){   
    pageTransition('moment_upload')
 }
})
}
const poster_share = async() => {
  Global.serverObj.send(Global.URL_VUPDATE, null, null, {
    'saveType': 'poster_share_cllick',
    'uniqID': Global.U_ID
  });
	function iOS() {
		// return true; 
		//remove this before go live, just for testing
		return [
			'iPad Simulator',
			'iPhone Simulator',
			'iPod Simulator',
			'iPad',
			'iPhone',
			'iPod'
		].includes(navigator.platform)
			// iPad on iOS 13 detection
			|| (navigator.userAgent.includes("Mac") && "ontouchend" in document)
      
	}


  const image = await fetch(`https://www.newsfeedsmartapp.com/Oreo_CocaCola/api/upload/${Global.U_ID}/poster_${Global.gameTry}.png`);

  const blob = await image.blob();
  const file = new File([blob], 'image.jpg', { type: 'image/jpeg' });
  const filesArray = [file];
  // alert(filesArray);

  if (iOS()) {
    var shareData = {
      files: filesArray,
    }
  } else {
    var shareData = {
      title: "oreoxcoca-cola",
      text: "oreoxcoca-cola",
      url: "https://www.oreoxcoca-cola.co.za/",
      files: filesArray
    }

  }

  try {
    await navigator.share(shareData)
    alert('Share was successful.')
  } catch (err) {
    // alert(err)
  }
}
const poster_retry = () => {
  const inputs = document.querySelectorAll('.poster_pages input');
  const selects = document.querySelectorAll('.poster_pages select');
  const textareas = document.querySelectorAll('.poster_pages textarea');
  document.querySelector('.preview').src='/UI/upload.svg'
        document.querySelector('.preview').classList.remove('active')
        document.querySelector('.reupload').classList.remove('active')

        document.querySelector('#poster_closeBtn').classList.remove('active')

  // Loop through and reset all input fields
  inputs.forEach(input => {
      if (input.type === 'text' || input.type === 'number') {
          input.value = '';  // Reset text and number inputs
      } else if (input.type === 'file') {
          input.value = '';  // Reset file input
      }
  });
  Global.serverObj.send(Global.URL_VUPDATE, null, null, {
    'saveType': 'poster_retry_click',
    'uniqID': Global.U_ID
  });
  // Loop through and reset all select fields
  selects.forEach(select => {
      select.selectedIndex = 0;  // Reset to the first option in the dropdown
  });

  // Loop through and reset all textarea fields
  textareas.forEach(textarea => {
      textarea.value = '';  // Reset textarea to empty string
  });
  Global.gameTry++;
  document.querySelector('#uploadStatus').innerHTML='Upload Picture'
 pageTransition('moment_selection')
}
const poster_download= () => {
  Global.serverObj.send(Global.URL_VUPDATE, null, null, {
    'saveType': 'poster_download_click',
    'uniqID': Global.U_ID
  });
  const imageUrl = `https://www.newsfeedsmartapp.com/Oreo_CocaCola/api/upload/${Global.U_ID}/poster_${Global.gameTry}.png`; // Replace with your image URL
 // Use fetch to get the image as a blob
 fetch(imageUrl)
 .then(response => response.blob())  // Convert the response to a Blob
 .then(blob => {
     const blobUrl = URL.createObjectURL(blob);  // Create a temporary Blob URL
     const link = document.createElement('a');
     link.href = blobUrl;
     link.download = 'downloaded-image.jpg'; // Set the filename for the download
     document.body.appendChild(link);
     link.click();
     document.body.removeChild(link);
     URL.revokeObjectURL(blobUrl);  // Revoke the Blob URL after downloading
 })
 .catch(err => console.error('Error downloading the image:', err));
		// var efw = setTimeout(() => {
		// 	clearTimeout(efw);
		// 	var element = document.createElement("a"); /* console.log(downloadURL) */
		// 	//   element.setAttribute(
		// 	//     "href",
		// 	//     "https://www.oreocreations.com/api/upload/" + Global.U_ID + "/poster_" + Global.gameTry + ".jpg"
		// 	//   );
		// 	element.setAttribute(
		// 		"href",
		// 		`https://www.newsfeedsmartapp.com/Oreo_CocaCola/api/upload/${Global.U_ID}/poster_${Global.gameTry}.png`
		// 	);
		// 	// element.setAttribute(
		// 	// 	"href",
		// 	// 	"https://newsfeedsmartapp.com/ChakraGoldDeepavali/api/upload/1146282793/poster_1.jpg"
		// 	// );
		// 	element.setAttribute("download", `oreo.jpg`);
		// 	document.body.appendChild(element);
		// 	element.click();
		// 	document.body.removeChild(element);

		// }, 250);
}
const updateAgeStatus= () => {
  AgeChecked= !AgeChecked;
  if(AgeChecked){
    document.querySelector("#t_c_age img:nth-child(2)").classList.add('active2');
  }else{
    document.querySelector("#t_c_age img:nth-child(2)").classList.remove('active2');
  }
}
const poster_updateAgeStatus= () => {
  AgeChecked= !AgeChecked;
  if(AgeChecked){
    document.querySelector("#poster_t_c_age img:nth-child(2)").classList.add('active2');
  }else{
    document.querySelector("#poster_t_c_age img:nth-child(2)").classList.remove('active2');
  }
}
const updateTCStatus= () => {
  TCChecked= !TCChecked;
  if(TCChecked){
    document.querySelector("#t_c_check img:nth-child(2)").classList.add('active2');
  }else{
    document.querySelector("#t_c_check img:nth-child(2)").classList.remove('active2');
  }
}
const poster_updateTCStatus= () => {
  poster_TCChecked= !poster_TCChecked;
  if(poster_TCChecked){
    document.querySelector("#poster_t_c_check img:nth-child(2)").classList.add('active2');
  }else{
    document.querySelector("#poster_t_c_check img:nth-child(2)").classList.remove('active2');
  }
}
const onBuyNowClick= () => {
  Global.serverObj.send(Global.URL_VUPDATE, null, null, {
    'saveType': 'buynowClick',
    'uniqID': Global.U_ID
  });
}
const updatePromotionStatus= () => {
  promotionChecked= !promotionChecked;

  if(promotionChecked){
    document.querySelector("#t_c_subscribe img:nth-child(2)").classList.add('active2');
  }else{
    document.querySelector("#t_c_subscribe img:nth-child(2)").classList.remove('active2');
  }
}
const poster_updatePromotionStatus= () => {

  poster_promotionChecked= !poster_promotionChecked;

  if(poster_promotionChecked){
    document.querySelector("#poster_t_c_subscribe img:nth-child(2)").classList.add('active2');
  }else{
    document.querySelector("#poster_t_c_subscribe img:nth-child(2)").classList.remove('active2');
  }

}
const onClickoMentNext = () => {
  slectedMoment=document.querySelector('#moment_select').value;
  if(slectedMoment==''){
   document.querySelector(".selection_content #moment_select").parentElement.classList.add("error");
   showError('Please pick your moment');
   return false;
  }else{
    document.querySelector(".selection_content #moment_select").parentElement.classList.remove("error");
 
    Global.serverObj.send(Global.URL_WS, (v) => {
      v= JSON.parse(v);

      if(v['status'] == 200){
        Global.serverObj.send(Global.URL_VUPDATE, null, null, {
          'saveType': 'poster_moment_next_click',
          'uniqID': Global.U_ID
        });
        pageTransition('moment_upload')
        document.querySelector(".moment_upload .selection_content").classList.add('active');
      }else{
        alert('somthing went wrong')
      }
      

    }, null, {
      'saveType': 'saveMoment', 
      'moment': slectedMoment,
      'gameTry':Global.gameTry,
      'uniqID': Global.U_ID
    });
  }
}
const onClickGenerateTrigger = () => {
  bestieName=document.querySelector('#bestieName').value;
  messageforBestie=document.querySelector('#messageforBestie').value;
  let isValid=true
  if(bestieName==''){
   document.querySelector(".selection_content #bestieName").parentElement.classList.add("error");
   showError('Please enter name');
   isValid=false
   return false;
  }else{
    document.querySelector(".selection_content #bestieName").parentElement.classList.remove("error");
  }
   if(messageforBestie==''){
    document.querySelector(".selection_content #messageforBestie").parentElement.classList.add("error");
    showError('Please enter message');
    isValid=false

    return false;
   }
   else{
    document.querySelector(".selection_content #messageforBestie").parentElement.classList.remove("error");
  }
  if(isValid){
    pageTransition('generate_poster')
    document.querySelector(".generate_poster .selection_content").classList.add('active');
    Global.serverObj.send(Global.URL_WS, (v) => {
      v= JSON.parse(v);

      if(v['status'] == 200){
        Global.serverObj.send(Global.URL_VUPDATE, null, null, {
          'saveType': 'poster_generate_click',
          'uniqID': Global.U_ID
        });
        document.querySelector('#posterImage').src=v['url']
        document.querySelector('.final_poster .center_logo').classList.add('active')
        pageTransition('final_poster')
        document.querySelector(".final_poster .selection_content").classList.add('active');
      }else{
        alert('somthing went wrong')
      }
      

    }, null, {
      'saveType': 'saveMessage', 
      'bestieName': bestieName,
      'messageforBestie': messageforBestie,
      'gameTry':Global.gameTry,
      'uploadType':uploadType,
      'uniqID': Global.U_ID
    });
  }
}
const onClickUploadNext = () => {
  const fileInput = document.getElementById('UploadedImage');
  console.log(fileInput);
  
  // Get the selected image
  let selectedImage = fileInput.files[0];
  console.log(selectedImage);
  
  // List of valid image types
  const validFileTypes = ['image/jpeg', 'image/png', 'image/gif'];
  
  // File size limit (2MB in bytes)
  const maxSizeInBytes = 2 * 1024 * 1024;
  if (selectedImage==''||selectedImage==undefined||selectedImage==null) {
    showError('Please upload an image');
    return false;
}
  // Validate the file type
  if (!validFileTypes.includes(selectedImage.type)) {
      showError('Please upload a valid image (JPEG, PNG, or GIF)');
      return false;
  }
  
  // Validate the file size
  // if (selectedImage.size > maxSizeInBytes) {
  //     showError('The image is too large. Please upload a file smaller than 2MB.');
  //     return false;
  // }
  
  // Convert the image to Base64 format and send via WebSocket
  const reader = new FileReader();
  reader.onload = function(e) {
      const base64Image = e.target.result; // The Base64 string of the image
  
      // Proceed with sending the image to the server via WebSocket
      Global.serverObj.send(Global.URL_WS, (v) => {
          v = JSON.parse(v);
  
          if (v['status'] === 200) {
              // Successful response
              Global.serverObj.send(Global.URL_VUPDATE, null, null, {
                'saveType': 'poster_upload_next_click',
                'uniqID': Global.U_ID
              });
              uploadType=v['uploadType'];
              pageTransition('moment_details');
              document.querySelector(".moment_details .selection_content").classList.add('active');
          } else {
              alert('Something went wrong');
          }
      }, null, {
          'saveType': 'saveImage',
          'image': base64Image,  // Send the Base64-encoded string
          'gameTry': Global.gameTry,
          'uniqID': Global.U_ID
      });
  };
  
  // Convert the image to Base64 format
  reader.readAsDataURL(selectedImage);
}
const pageTransition = (page,prevPage) => {
  $('.posterPage').removeClass('active')
setTimeout(() => {
  $('.posterPage').css('display','none')
  document.querySelector(`.${page}`).style.display='block';
  document.querySelector(`.${page} .label2`)&&document.querySelector(`.${page} .label2`).classList.add('active');

  setTimeout(() => {
    document.querySelector(`.${page}`).classList.add('active');
    $('.posterPage').addClass('active')
    setTimeout(animateItems.bind(this,page), 200)
    // setTimeout(animateRegisterItems.bind(this,"poster_"), 250)
  document.querySelector(`.${page} .logo_pulse1`).classList.add('active');
    document.querySelector(`.${page} .logo_pulse2`).classList.add('active');
    document.querySelector(`.${page} .center_logo img`).classList.add('active');
    setTimeout(() => {
      document.querySelector(`.${page}  .oreo_country_bottle`).classList.add('active');
    }, 750);
    setTimeout(() => {
      document.querySelector(`.${page}  .oreo_country_cookie`).classList.add('active');

    }, 1000);
  //   setTimeout(() => {
  //     document.querySelector(".poster_user_register .oreo_register_bottle").classList.add('active');
  //   }, 750);
  //   setTimeout(() => {
  //     document.querySelector(".poster_user_register .oreo_register_cookie").classList.add('active');

  //   }, 1000);
  }, 500);
  curPage=page;
  if(page=='moment_upload'){
    document.querySelector('#back').classList.add('active')
  }else if(page=='final_poster'){
    document.querySelector('#back').classList.remove('active')
    document.querySelector('#poster_closeBtn').classList.add('active')
  }
  if(page=='moment_selection'){
    document.querySelector('#back').classList.remove('active')
  }
}, 500);
}
const checkUserRegistration= () => {
  let registerStatus = validateRegister();

  if (registerStatus) {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    let tempData = null;
    var ga_cid = "1844306653.1689247851";
    ga_cid = getCookie2('_ga');

    if (typeof ga_cid === "undefined" || ga_cid === null) {
      ga_cid = "1844306653.1689247851";
    }

    let utm_term = urlParams.get("utm_term");
    let utm_medium = urlParams.get("utm_medium");
    let utm_source = urlParams.get("utm_source");
    let utm_campaign = urlParams.get("utm_campaign");
    let utm_content = urlParams.get("utm_content");
    window.htk = "";
    window.htk = getCookie("hubspotutk");
    Global.serverObj.send(Global.URL_WS, onApiResponse, null, {
      'saveType': 'formData',
      'utm_term': utm_term,
      'utm_campaign': utm_campaign,
      'utm_medium': utm_medium,
      'utm_source': utm_source,
      'utm_content': utm_content,
      'ga_cid': ga_cid,
      'htk': window.htk,
      'tc': promotionChecked?'true':'false',
      'name': registerUserName,
      'email': registerEmail,
      'country': countrySelected,
      'mobile': countryCodes[countrySelected]+String(registerMobile),
      'dob': dob,
      // 'uniqueCode': registerUniqCode,
      'uniqID': Global.U_ID
    });


    registerActive= false;
    // showCoupon();
  }
  
}
const poster_checkUserRegistration= () => {
  let registerStatus = poster_validateRegister();

  if (registerStatus) {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    let tempData = null;
    var ga_cid = "1844306653.1689247851";
    ga_cid = getCookie2('_ga');

    if (typeof ga_cid === "undefined" || ga_cid === null) {
      ga_cid = "1844306653.1689247851";
    }

    let utm_term = urlParams.get("utm_term");
    let utm_medium = urlParams.get("utm_medium");
    let utm_source = urlParams.get("utm_source");
    let utm_campaign = urlParams.get("utm_campaign");
    let utm_content = urlParams.get("utm_content");
    window.htk = "";
    window.htk = getCookie("hubspotutk");
    Global.serverObj.send(Global.URL_WS, poster_onApiResponse, null, {
      'saveType': 'poster_formData',
      'utm_term': utm_term,
      'utm_campaign': utm_campaign,
      'utm_medium': utm_medium,
      'utm_source': utm_source,
      'utm_content': utm_content,
      'gameTry':1,
      'ga_cid': ga_cid,
      'htk': window.htk,
      'tc': poster_promotionChecked?'true':'false',
      'name': poster_registerUserName,
      'email': poster_registerEmail,
      'dob': poster_dob,
      'country': countrySelected,
      'mobile': countryCodes[countrySelected]+String(poster_registerMobile),
      // 'uniqueCode': registerUniqCode,
      'uniqID': Global.U_ID
    });


    registerActive= false;
    // showCoupon();
  }
  
}
const onApiResponse = (v) => {
  v= JSON.parse(v);
  Global.serverObj.send(Global.URL_VUPDATE, null, null, {
    'saveType': 'submit_click',
    'uniqID': Global.U_ID
  });
  if(v['status'] == 200){

    prizeValid= v['prize_key'];
    document.querySelector(".scratch-card-image").src= `UI/${prizeMap[prizeValid]['prize']}.png`;
    showCoupon();
  }else if(v['status'] == 400){
    document.querySelector(".scratch-card-image").src= `UI/no_prize.png`;
    showCoupon();
  }else{
    registerActive= true;
  }
  // if(v['code'] == 403 && Object.keys(errorMapping).indexOf(`error${v['error_key']}`) !=-1){
  //   showError(errorMapping[`error${v['error_key']}`][language]);
  //   return false;
  // }
  // if(v['code'] == 200 && v['coupon'] === "Invalid"){
  //   showError(language=='en'?'Invalid':'غير صالح');
  //   return false;
  // }
  
  // if(v['code'] != 403){
  //   showCongrats();
  // }

}

const poster_onApiResponse = (v) => {
  v= JSON.parse(v);
console.log(v);

  if(v['status'] == 200){
    // document.querySelector(`.${page}`).style.display='block';
    pageTransition('moment_selection')
    document.querySelector(".moment_selection .selection_content").classList.add('active');
    Global.serverObj.send(Global.URL_VUPDATE, null, null, {
      'saveType': 'poster_submit_click',
      'uniqID': Global.U_ID
    });
  }else if(v['status'] == 400){
alert('somthing went wrong')
  }


}
const validateRegister = () => {
  if (!registerActive) return false;

  let isValid = true;
  registerUserName = document.querySelector(".register_content #name").value.trim();
  registerEmail = document.querySelector(".register_content #email").value.trim();
  registerMobile = document.querySelector(".register_content #mobile").value.trim();
  // registerUniqCode = document.querySelector("#uniq_code").value.trim();
  dob = document.querySelector(".register_content #dob").value.trim();
  // registerUniqCode = document.querySelector("#uniq_code").value.trim();
  const birthDate = new Date(dob);
  const today = new Date();
  const ageInMilliseconds = today - birthDate;
  const millisecondsPerYear = 1000 * 60 * 60 * 24 * 365.25; // Approximate milliseconds per year
  const age = ageInMilliseconds / millisecondsPerYear;
  
console.log(age);

  if (registerUserName.length == 0) {
    document.querySelector(".register_content #name").parentElement.classList.add("error");
    isValid = false;
    showError('Please enter a valid name');
    return false;
  } else if (registerUserName.length != 0) {
    document.querySelector(".register_content #name").parentElement.classList.remove("error")
  }
  if (registerEmail.length == 0) {
    document.querySelector(".register_content #email").parentElement.classList.add("error");
    isValid = false;
    showError('Please enter a valid email');
    return false;
  }
  if (!/^[\w.-]+@[\w.-]+\.\w{2,}$/.test(registerEmail)) {
    document.querySelector(".register_content #email").parentElement.classList.add("error");
    isValid = false;
    showError('Please enter a valid email');
    return false;
  } else if ((/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(registerEmail))) {
    document.querySelector(".register_content #email").parentElement.classList.remove("error")
  }

  if ((registerMobile.length == 0 || isNaN(registerMobile))) {
    document.querySelector(".register_content #mobile").parentElement.classList.add("error");
    isValid = false;
    showError('Please enter a valid mobile');
    return false;
  } 
  else if(
    registerMobile.length<7 ||
    registerMobile.length>11 
  ){
    console.log('limit worng')
    document.querySelector(".register_content #mobile").parentElement.classList.add("error");
    isValid = false;
    showError('Please enter a valid mobile');
    return false;
  }
  else {
    document.querySelector(".register_content #mobile").parentElement.classList.remove("error")
  }
  if ((dob.length == 0)) {
    document.querySelector(".register_content #dob").parentElement.classList.add("error");
    isValid = false;
    console.log(dob.length );
    console.log(dob,isNaN(dob) );
    
    showError('Please enter a valid dob');
    return false;
  }
  if (age < 13) {
    document.querySelector(".register_content #dob").parentElement.classList.add("error");
    isValid = false;
    showError('You must be at least 13 years old.');
    return false;
}
else {
  document.querySelector(".register_content #dob").parentElement.classList.remove("error")
}
  // if (!AgeChecked) {
  //   isValid = false;
  //   showError('Please verify your age is above 13.');
  //   return false;
  // }

  if (!TCChecked) {
    isValid = false;
    showError('Please accept T&C and Privacy Notice.');
    return false;
  }
  return true;
  // if (isValid) {
  //   Global.serverObj.send(Global.URL_VERIFY, this.onEmailVerify.bind(this), null, {
  //     "email": this.email,
  //     "uniqID": Global.U_ID
  //   }, 'POST', null, false);
  // }
}
const poster_validateRegister = () => {
  // if (!registerActive) return false;

  let isValid = true;
  poster_registerUserName = document.querySelector(".register_content #poster_name").value.trim();
  poster_registerEmail = document.querySelector(".register_content #poster_email").value.trim();
  poster_registerMobile = document.querySelector(".register_content #poster_mobile").value.trim();
  poster_dob = document.querySelector(".register_content #poster_dob").value.trim();
  // registerUniqCode = document.querySelector("#uniq_code").value.trim();
  const birthDate = new Date(poster_dob);
  const today = new Date();
  const ageInMilliseconds = today - birthDate;
  const millisecondsPerYear = 1000 * 60 * 60 * 24 * 365.25; // Approximate milliseconds per year
  const age = ageInMilliseconds / millisecondsPerYear;
  
  if (poster_registerUserName.length == 0) {
    document.querySelector(".register_content #poster_name").parentElement.classList.add("error");
    isValid = false;
    showError('Please enter a valid name');
    return false;
  } else if (poster_registerUserName.length != 0) {
    document.querySelector(".register_content #poster_name").parentElement.classList.remove("error")
  }
  if (poster_registerEmail.length == 0) {
    document.querySelector(".register_content #poster_email").parentElement.classList.add("error");
    isValid = false;
    showError('Please enter a valid email');
    return false;
  }
  if (!/^[\w.-]+@[\w.-]+\.\w{2,}$/.test(poster_registerEmail)) {
    document.querySelector(".register_content #poster_email").parentElement.classList.add("error");
    isValid = false;
    showError('Please enter a valid email');
    return false;
  } else if ((/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(poster_registerEmail))) {
    document.querySelector(".register_content #poster_email").parentElement.classList.remove("error")
  }

  if ((poster_registerMobile.length == 0 || isNaN(poster_registerMobile))) {
    document.querySelector(".register_content #poster_mobile").parentElement.classList.add("error");
    isValid = false;
    showError('Please enter a valid mobile');
    return false;
  } 
  else if(
    poster_registerMobile.length<7 ||
    poster_registerMobile.length>11 
  ){
    console.log('limit worng')
    document.querySelector(".register_content #poster_mobile").parentElement.classList.add("error");
    isValid = false;
    showError('Please enter a valid mobile');
    return false;
  }
  else {
    document.querySelector(".register_content #poster_mobile").parentElement.classList.remove("error")
  }
  if ((poster_dob.length == 0)) {
    document.querySelector(".register_content #poster_dob").parentElement.classList.add("error");
    isValid = false;
    console.log(poster_dob.length );
    console.log(poster_dob,isNaN(poster_dob) );
    
    showError('Please enter a valid dob');
    return false;
  }
  if (age < 13) {
    document.querySelector(".register_content #poster_dob").parentElement.classList.add("error");
    isValid = false;
    showError('You must be at least 13 years old.');
    return false;
}
else {
  document.querySelector(".register_content #poster_dob").parentElement.classList.remove("error")
}
  // if (!AgeChecked) {
  //   isValid = false;
  //   showError('Please verify your age is above 13.');
  //   return false;
  // }

  if (!poster_TCChecked) {
    isValid = false;
    showError('Please accept T&C and Privacy Notice.');
    return false;
  }
  return true;
  // if (isValid) {
  //   Global.serverObj.send(Global.URL_VERIFY, this.onEmailVerify.bind(this), null, {
  //     "email": this.email,
  //     "uniqID": Global.U_ID
  //   }, 'POST', null, false);
  // }
}
const showCoupon= () => {
  // if(!document.querySelector(".user_register").classList.contains('active')) return false;

  // document.querySelector(".user_register").classList.remove('remove');
  // setTimeout(() => {
  //   document.querySelector(".user_register .oreo_register_bottle").classList.remove('active');
  // }, 0);
  // setTimeout(() => {
  //   document.querySelector(".user_register .oreo_register_cookie").classList.remove('active');
  // }, 250);
  setTimeout(() => {
    // document.querySelector(".user_register .logo_pulse1").classList.remove('active');
    // document.querySelector(".user_register .logo_pulse2").classList.remove('active');
    // document.querySelector(".user_register .center_logo img").classList.remove('active');
    document.querySelector(".user_register .register_content").classList.remove('active');
    // document.querySelector(".user_register .user_register_header").classList.remove('active');
    // document.querySelector(".user_register .user_register_footer").classList.remove('active');
  }, 0);
  setTimeout(() => {
    
    document.querySelector(".scratchcard_sec").classList.add('active');
  }, 500);
  
}
const unlockSpotify= () => {
  spotifyUnlock.classList.add('active2');
  Global.serverObj.send(Global.URL_VUPDATE, null, null, {
    'saveType': 'unlockBestBuddyModeClick',
    'uniqID': Global.U_ID
  });
  setTimeout(() => {
    location.href=spotifyEnabledCountries[countrySelected];
  }, 500);
}
const closePrize= () => {
  Global.serverObj.send(Global.URL_VUPDATE, null, null, {
    'saveType': 'close_click',
    'uniqID': Global.U_ID
  });
  setTimeout(() => {
    document.querySelector(".user_register .oreo_register_bottle").classList.remove('active');
  }, 0);
  setTimeout(() => {
    document.querySelector(".user_register .oreo_register_cookie").classList.remove('active');
  }, 250);
  setTimeout(() => {
    document.querySelector(".scratchcard_sec").classList.remove('active');
    // document.querySelector(".scratchcard_sec").classList.remove('active');
    document.querySelector(".user_register .logo_pulse1").classList.remove('active');
    document.querySelector(".user_register .logo_pulse2").classList.remove('active');
    document.querySelector(".user_register .center_logo img").classList.remove('active');
    document.querySelector(".user_register .user_register_header").classList.remove('active');
    document.querySelector(".user_register .user_register_footer").classList.remove('active');
    document.querySelector(".user_register").classList.remove('active');
  }, 500);
}
const poster_closePrize= () => {
 
  setTimeout(() => {
    document.querySelector(".poster_user_register .oreo_register_bottle").classList.remove('active');
    $('.posterPage').removeClass('active')
    document.querySelector(`.poster_pages`).classList.remove('active');
    document.querySelector("#poster_closeBtn").classList.remove('active')
    // $('.posterPage').css('display','none')
    document.querySelector(`.final_poster`).style.display='none';
    document.querySelector(`.final_poster .logo_pulse1`).classList.remove('active');
    document.querySelector(`.final_poster .logo_pulse2`).classList.remove('active');
    // document.querySelector(`.final_poster} .center_logo img`).classList.add('active');
      document.querySelector(`.final_poster .oreo_country_bottle`).classList.remove('active');
      document.querySelector(`.final_poster  .oreo_country_cookie`).classList.remove('active');
      const inputs = document.querySelectorAll('.poster_pages input');
      const selects = document.querySelectorAll('.poster_pages select');
      const textareas = document.querySelectorAll('.poster_pages textarea');
      document.querySelector('.preview').src='/UI/upload.svg'
            document.querySelector('.preview').classList.remove('active')
            document.querySelector('.reupload').classList.remove('active')
    
            document.querySelector('#poster_closeBtn').classList.remove('active')
            Global.serverObj.send(Global.URL_VUPDATE, null, null, {
              'saveType': 'poster_close_click',
              'uniqID': Global.U_ID
            });
      // Loop through and reset all input fields
      inputs.forEach(input => {
          if (input.type === 'text' || input.type === 'number') {
              input.value = '';  // Reset text and number inputs
          } else if (input.type === 'file') {
              input.value = '';  // Reset file input
          }
      });
    
      // Loop through and reset all select fields
      selects.forEach(select => {
          select.selectedIndex = 0;  // Reset to the first option in the dropdown
      });
    
      // Loop through and reset all textarea fields
      textareas.forEach(textarea => {
          textarea.value = '';  // Reset textarea to empty string
      });
      Global.gameTry++;
      document.querySelector('#uploadStatus').innerHTML='Upload Picture'
  }, 0);
  setTimeout(() => {
    document.querySelector(".poster_user_register .oreo_register_cookie").classList.remove('active');
  }, 250);
  setTimeout(() => {
    document.querySelector(".scratchcard_sec").classList.remove('active');
    // document.querySelector(".scratchcard_sec").classList.remove('active');
    document.querySelector(".poster_user_register .logo_pulse1").classList.remove('active');
    document.querySelector(".poster_user_register .logo_pulse2").classList.remove('active');
    document.querySelector(".poster_user_register .center_logo img").classList.remove('active');
    // document.querySelector(".poster_user_register .user_register_header").classList.remove('active');
    // document.querySelector(".poster_user_register .user_register_footer").classList.remove('active');
    document.querySelector(".poster_user_register").classList.remove('active');
  }, 500);
}
const playOrPlauseVideo= () => {
  // if(videoToPlay.paused){
  //   videoToPlay.play();
  //   // document.querySelector("#videoPlay").classList.remove('active');
  //   // document.querySelector(".video_sec .cover").classList.remove('active');
  // }else{
  //   videoToPlay.pause();
  //   // document.querySelector("#videoPlay").classList.add('active');
  //   // document.querySelector(".video_sec .cover").classList.add('active');
  // }
}
const saveCountry= () => {
  countrySelected= document.querySelector("#country_select").value;
  document.querySelector(".field .code").innerHTML= countryCodes[countrySelected];
}
const checkIfCountrySelected= () => {
  if(countrySelected == null || countrySelected.length == 0){
    showError("Please select your country.")
  }else{
    Global.serverObj.send(Global.URL_VUPDATE, null, null, {
      'saveType': 'countryProceedClick',
      'uniqID': Global.U_ID
    });
    Global.serverObj.send(Global.URL_WS, null, null, {
      'saveType': 'saveCountry',
      'country': countrySelected,
      'uniqID': Global.U_ID
    });
    if(countrySelected != 'southafrica'){
      viewMerchandise.remove();
      document.querySelector(".merchandise_sec").classList.add('hidden');
      // document.querySelector(".oreo_bottle2").classList.add('hidden');
      document.querySelector("#bannerLabel").src='/UI/label2.png';
    }
    if(Object.keys(spotifyEnabledCountries).indexOf(countrySelected) == -1){
      spotifyUnlock.remove();
      document.querySelector(".bestie_mode").classList.add('hidden');
    }
    //
    hideCountrySelection();
    setTimeout(showBannerAndEnableScroll.bind(this), 1000);
    // if(countrySelected!='kenya' && countrySelected!='tanzania'){
    //   document.querySelector('.oreo_bottle1').style.display='none'
    // }
    if(countrySelected=='mauritius' && countrySelected=='zambia' && countrySelected=='mozambique'){
      document.querySelector('.bestie_mode').classList.remove('active')
    }
    if(countrySelected=='southafrica'){
      document.querySelector('.bestie_poster_sec').style.display='none'
      document.querySelector('.mix_sec .title').innerHTML='THE BEST MIX YOU HAVE SEEN SINCE YOU AND YOUR BEST BUDDIES FIRST MET!<br> TASTE THE NEW OREO&#174; COCA-COLA&#8482; TODAY'
      document.querySelector('.bestie_mode .title').innerHTML='BEST BUDDIES MODE EXPERIENCE'
      document.querySelector('.btn_unlock .label').innerHTML='Unlock Best Buddies Mode'
      document.querySelector('#youtubeLink').src = 'https://www.youtube.com/embed/6naE6dzidPI';
    }
    document.querySelector('.oreo_bottle2').style.display='none'
  }
}
const showBannerAndEnableScroll= () => {
    document.querySelector(".banner_section").classList.add('active');
    setTimeout(() => {
      document.querySelector("canvas.webgl").classList.add('active');
      
    }, 200);
    setTimeout(() => {
      document.querySelector(".wrapper").classList.add('active');
    }, 1000);

    animateBannerItems("");
    
    document.querySelector(".wrapper").addEventListener("scroll", onScroll);
  
}
const onScroll= () => {
  const cookieSpaceSecHeight= document.querySelector(".cookie_space_sec").getBoundingClientRect().height;
  const buynowSecHeight= document.querySelector(".buynow_sec").getBoundingClientRect().height;
  const cookieSpaceSecOffsetY= Math.min(Math.max(0,document.querySelector(".cookie_space_sec").getBoundingClientRect().top*-1+cookieSpaceSecHeight*0.65), cookieSpaceSecHeight);
  const buynowSecOffsetY= Math.min(Math.max(0,document.querySelector(".buynow_sec").getBoundingClientRect().top*-1+buynowSecHeight*0.9), cookieSpaceSecHeight);
  const mixSecTop= document.querySelector(".mix_sec").getBoundingClientRect().top-window.innerHeight;
  const oreoBottle1Top= document.querySelector(".oreo_bottle1").getBoundingClientRect().top-window.innerHeight;
  const oreoBottle2Top= document.querySelector(".oreo_bottle2").getBoundingClientRect().top-window.innerHeight;
  const bestieModeTop= document.querySelector(".bestie_mode").getBoundingClientRect().top-window.innerHeight;
  const merchandiseSecTop= document.querySelector(".merchandise_sec").getBoundingClientRect().top-window.innerHeight;

  const buynowSec= document.querySelector("footer").getBoundingClientRect().top-window.innerHeight;

  if(mixSecTop <= -200 && !document.querySelector(".mix_sec").classList.contains("active")){
    document.querySelector(".mix_sec").classList.add('active');
    document.querySelector(".mix_sec .title").classList.add('active');
    document.querySelector(".mix_sec .video_sec").classList.add('active');
  }
  if(oreoBottle1Top <= -200 && !document.querySelector(".oreo_bottle1").classList.contains("active")){
    document.querySelector(".oreo_bottle1").classList.add('active');
    document.querySelector(".oreo_bottle1 img:nth-child(1)") && document.querySelector(".oreo_bottle1 img:nth-child(1)").classList.add('active');
    document.querySelector(".oreo_bottle1 img:nth-child(2)") && document.querySelector(".oreo_bottle1 img:nth-child(2)").classList.add('active');
    document.querySelector(".oreo_bottle1 video") && document.querySelector(".oreo_bottle1 video").classList.add('active');
  }
  if(oreoBottle2Top <= -200 && !document.querySelector(".oreo_bottle2").classList.contains("active")){
    document.querySelector(".oreo_bottle2").classList.add('active');
    document.querySelector(".oreo_bottle2 img:nth-child(1)") && document.querySelector(".oreo_bottle2 img:nth-child(1)").classList.add('active');
    document.querySelector(".oreo_bottle2 img:nth-child(2)") && document.querySelector(".oreo_bottle2 img:nth-child(2)").classList.add('active');
    document.querySelector(".oreo_bottle2 video") && document.querySelector(".oreo_bottle2 video").classList.add('active');
  }

  if(bestieModeTop <= -300 && !document.querySelector(".bestie_mode").classList.contains("active")){
    document.querySelector(".bestie_mode").classList.add('active');
    document.querySelector(".bestie_mode .title").classList.add('active');
    document.querySelector(".bestie_mode .bestie_img").classList.add('active');
    document.querySelector(".bestie_mode .bestie_desc").classList.add('active');
    if(document.querySelector(".bestie_mode .btn_unlock")){
      document.querySelector(".bestie_mode .btn_unlock").classList.add('active');
    }
  
  }
  if(merchandiseSecTop <= -200 && !document.querySelector(".merchandise_sec").classList.contains("active")){
    document.querySelector(".merchandise_sec").classList.add('active');
    document.querySelector(".merchandise_sec .title").classList.add('active');
    document.querySelector(".merchandise_sec .content").classList.add('active');
    if(document.querySelector(".merchandise_sec .win_merchandise")){
      document.querySelector(".merchandise_sec .win_merchandise").classList.add('active');
    }
  }

  if(buynowSec <= 0 && !document.querySelector(".buynow_sec .buynow_content").classList.contains("active")){
    document.querySelector(".buynow_sec .buynow_content").classList.add("active");
    document.querySelector(".buynow_sec .buynow_content .label").classList.add("active");
    document.querySelector(".buynow_sec .buynow_content #buyNow").classList.add("active");
  }

  Global.events.dispatchEvent({
      type: "GAMESCENE_EVENTS",
      message: {
          "event_type": "update_cookie_position",
          data: {
            value1: cookieSpaceSecOffsetY/cookieSpaceSecHeight,
            value2: buynowSecOffsetY/buynowSecHeight
          }
      }
  });

}

const showError= (msg) => {
  errorTO && clearTimeout(errorTO);
 
  let errorInfo= document.querySelector(".errorInfo")
  errorInfo.innerHTML= msg;
  errorInfo.classList.add('active');
  errorTO= setTimeout(() => {
    errorInfo.classList.remove('active');
  }, 2000)
}
const activatePage = () => {

  /* Animate Home scree Elements. */
  
  gsap.to(".scroll_hint .icon *", {
    y: `-=7`,
    duration: 0.75,
    ease: "expo.out",
    yoyo: true,
    repeat: -1,
    // repeatDelay:1,
  });

  /* Animate Country Selection Items. */

  gsap.to(".oreo_country_bottle", {
    rotate: `-=3`,
    y: '-=10',
    duration: 10,
    ease: "back.inOut(10.7)",
    yoyo: true,
    repeat: -1,
    repeatDelay: 0,
  });
  gsap.to(".oreo_country_cookie", {
    rotate: `+=5`,
    y: '-=5',
    x: '+=10',
    duration: 8,
    ease: "back.inOut(4.7)",
    yoyo: true,
    repeat: -1,
    repeatDelay: 0,
  });

  activateMerchandiseCarousel();
  activateScratchCard();
  showCountrySelection();

  // hideCountrySelection();
  // countrySelected="southafrica";
  // setTimeout(showBannerAndEnableScroll.bind(this), 1000);


  // animateBannerItems("poster_");

  // showCoupon();


  activateItems();


  document.querySelectorAll(".t_c_trigger").forEach((trigger) => {
    trigger.addEventListener("click", () => {
      $("#t_c").modal('show');
    })
  });

  document.querySelectorAll(".privacy_trigger").forEach((trigger) => {
    trigger.addEventListener("click", () => {
      window.open('https://privacy.mondelezinternational.com/za/en-ZA/privacy-policy/', '_blank');
      // $("#privacy_notice").modal('show');
    })
  });
}

let previousUrl=null;
const activateScratchCard = () => {
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  const isMobile = /Mobi|Android/i.test(navigator.userAgent); // Basic mobile detection

  const scratchCardCover = document.querySelector('.scratch-card-cover');
  const scratchCardCanvasRender = document.querySelector('.scratch-card-canvas-render');
  const scratchCardCoverContainer = document.querySelector('.scratch-card-cover-container');
  const scratchCardText = document.querySelector('.scratch-card-text');
  const scratchCardImage = document.querySelector('.scratch-card-image');

  const canvas = document.querySelector('canvas');
  const context = canvas.getContext('2d');
  let positionX;
  let positionY;
  let clearDetectionTimeout = null;

  const devicePixelRatio = window.devicePixelRatio || 1;

  const canvasWidth = canvas.offsetWidth * devicePixelRatio;
  const canvasHeight = canvas.offsetHeight * devicePixelRatio;

  canvas.width = canvasWidth;
  canvas.height = canvasHeight;


    // Set canvas to transparent background
    // context.fillStyle = 'rgba(0, 0, 0, 0)';
    // context.fillRect(0, 0, canvas.width, canvas.height);

  if (isSafari) {
    canvas.classList.add('hidden');
  }
  
  // Helper function to start drawing
  const startDrawing = (e) => {
    if(!document.querySelector(".scratchcard_sec").classList.contains("active")) return false;

    scratchCardCover.classList.remove('shine');
    ({
      x: positionX,
      y: positionY
    } = getPosition(e));
    clearTimeout(clearDetectionTimeout);

   
    // Add move listener
    if (isMobile) {
      canvas.addEventListener('touchmove', plot);
    } else {
      canvas.addEventListener('pointermove', plot);
    }
  };

  // Helper function to stop drawing
  const stopDrawing = (e) => {
    if(!document.querySelector(".scratchcard_sec").classList.contains("active")) return false;

    if (isMobile) {
      canvas.removeEventListener('touchmove', plot);
    } else {
      canvas.removeEventListener('pointermove', plot);
    }
    clearDetectionTimeout = setTimeout(() => {
      checkBlackFillPercentage();
    }, 500);
  };

  // Add initial event listeners

  if (isMobile) {
    canvas.addEventListener('touchstart', startDrawing);
    window.addEventListener('touchend', stopDrawing/* , { once: true } */);
  } else {
    canvas.addEventListener('pointerdown', startDrawing);
    window.addEventListener('pointerup', stopDrawing/* , { once: true } */);
  }

  const checkBlackFillPercentage = () => {
    const imageData = context.getImageData(0, 0, canvasWidth, canvasHeight);
    const pixelData = imageData.data;

    let blackPixelCount = 0;

    for (let i = 0; i < pixelData.length; i += 4) {
      const red = pixelData[i];
      const green = pixelData[i + 1];
      const blue = pixelData[i + 2];
      const alpha = pixelData[i + 3];

      if (red === 0 && green === 0 && blue === 0 && alpha === 255) {
        blackPixelCount++;
      }
    }

    const blackFillPercentage = blackPixelCount * 100 / (canvasWidth * canvasHeight);

    if (blackFillPercentage >= 40 && !codeRevealed) {
      codeRevealed = true;
      Global.serverObj.send(Global.URL_VUPDATE, null, null, {
        'saveType': 'scratchCardRevealed',
        'uniqID': Global.U_ID
      });
      if(prizeValid!=null){
        document.querySelector('.scratchcard_title').innerHTML=prizeMap[prizeValid]['title']

        
      }else{
        // document.querySelector('.scratchcard_title').innerHTML='BETTER LUCK NEXT TIME';
        document.querySelector('.scratchcard_title').innerHTML='';

      }
      // document.querySelector('.scratchcard_title').innerHTML='CONGRATULATIONS! YOU HAVE WON OREO X COCA-COLA LIMITED EDITION MERCH'
      document.querySelector("#closeBtn").classList.add('active');
      document.querySelector("#buynowBtn").classList.add('active');
      scratchCardCoverContainer.classList.add('clear');
      if(prizeValid != null){
        confetti({
          particleCount: 100,
          spread: 90,
          origin: {
            y: (scratchCardText.getBoundingClientRect().bottom + 60) / window.innerHeight,
          },
        });
        document.querySelector(".scratchcard_info").classList.add('active');
      }
      
      scratchCardText.textContent = '';
      scratchCardImage.classList.add('animate');
      scratchCardCoverContainer.addEventListener('transitionend', () => {
        scratchCardCoverContainer.classList.add('hidden');
      }, { once: true });
    }
  };

  const getPosition = (e) => {
    const { clientX, clientY } = isMobile ? e.touches[0] : e;
    const { left, top } = canvas.getBoundingClientRect();
    return { x: clientX - left, y: clientY - top };
  };

  const plotLine = (context, x1, y1, x2, y2) => {
    // context.globalCompositeOperation = 'source-over';

    var diffX = Math.abs(x2 - x1);
    var diffY = Math.abs(y2 - y1);
    var dist = Math.sqrt(diffX * diffX + diffY * diffY);
    var step = dist / 50;
    var i = 0;
    var t;
    var x;
    var y;

    while (i < dist) {
      t = Math.min(1, i / dist);

      x = x1 + (x2 - x1) * t;
      y = y1 + (y2 - y1) * t;
      context.beginPath();
      context.arc(x*2, y*2, window.innerWidth * 0.0175 * (isMobile ? 4 : 2), 0, Math.PI * 2);
      context.fill();

      i += step;
    }
  };

  const setImageFromCanvas = () => {
    canvas.toBlob((blob) => {
      const url = URL.createObjectURL(blob);
      previousUrl = scratchCardCanvasRender.src;
      scratchCardCanvasRender.src = url;
      if (!previousUrl) {
        scratchCardCanvasRender.classList.remove('hidden');
      } else {
        URL.revokeObjectURL(previousUrl);
      }
      previousUrl = url;
    });
  };

  let setImageTimeout = null;

  const plot = (e) => {
    const { x, y } = getPosition(e);
    plotLine(context, positionX, positionY, x, y);
    positionX = x;
    positionY = y;
    if (isSafari) {
      clearTimeout(setImageTimeout);

      setImageTimeout = setTimeout(() => {
        setImageFromCanvas();
      }, 5);
    }
  };
};

const setUID = (v) => {
  v = JSON.parse(v);
  Global.gameKey = v['game_key'];
  Global.U_ID = v['uid'];

}

window.onload = ()=>{
    Global.serverObj = new Server();

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    let utm_source = urlParams.get("utm_source");
    Global.serverObj.send(Global.URL_CREATE, setUID.bind(this), null, {
      'fresh': true,
      'utm_source': utm_source,
      'device': isMobile ? 'mobile' : 'web'
    });

    const home = new Home();
    home.init();
    activatePage();
};


function getCookie(cookie) {

  return document.cookie.split(';').reduce(function (prev, c) {
    var arr = c.split('=');
    return (arr[0].trim() === cookie) ? arr[1] : prev;
  }, undefined);
}

function getCookie2(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  // console.log(name);
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}
