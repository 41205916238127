let Global={
    URL_CREATE: "https://www.newsfeedsmartapp.com/Oreo_CocaCola/api/createUser.php",
    URL_VUPDATE: "https://www.newsfeedsmartapp.com/Oreo_CocaCola/api/valueUpdator.php",
    URL_WS: "https://www.newsfeedsmartapp.com/Oreo_CocaCola/api/webservice.php",
    gameKey:null,
    U_ID:null,
    BLOOM_SCENE:1,
    itemsToRayCastRight:[],
    itemsToRayCastLeft:[],
    itemsToRayCastDown:[],
    itemsToRayCastUp:[],
    itemsToCollect:[],
    itemsToAvoid:[],
    energyTotal:0,
    playCount:0,
    scoreTotal:0,
    backgroundIntensity:0.8,
    delta:1,
    gameTry:1,
    fps:60,
    controlType:null,
    assets:{
        "cubemap":{
            "0":{
                'key':'environmentMapTexture',
                "path":[
                    'textures/environmentMaps/0/px.png',
                    'textures/environmentMaps/0/nx.png',
                    'textures/environmentMaps/0/py.png',
                    'textures/environmentMaps/0/ny.png',
                    'textures/environmentMaps/0/pz.png',
                    'textures/environmentMaps/0/nz.png'
                ],
                "asset":null
            }
        },
        "glbs":{
            "cookie":{
                'key':'cookie',
                "path": [
                    'models/cookie.glb'
                ],
                "asset":null
            }
        },
        "textures":{
           
            
        }
    }
}
export{
    Global
}