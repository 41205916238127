import * as THREE from 'three'

let Events=null;
export default class EventDispatcher extends THREE.EventDispatcher{
    constructor(){
        super();
    }
    static getObj(){
        if(Events===null){
            Events=new EventDispatcher();
        }
        return Events;
    }
    static kill(){
        Events=null;
    }
}
