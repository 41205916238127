import * as THREE from 'three'
import EventDispatcher from "../common/EventDispatcher";
import { Global } from "../common/global";
import { Water } from 'three/addons/objects/Water.js';
import { Sky } from 'three/examples/jsm/objects/Sky.js';
import { isMobile } from 'mobile-device-detect';
export default class Room{
    constructor(scene, renderer){
        this.scene= scene;
        this.renderer=renderer;
       
    }
    init(){
        this.events = EventDispatcher.getObj();
        this.events.addEventListener("LOADER_EVENTS", this.onEvents.bind(this));
        this.events.addEventListener("GAME_EVENTS", this.onEvents.bind(this));
        this.events.addEventListener("GAMESCENE_EVENTS", this.onEvents.bind(this));

      
        this.tlValue1=0;
        this.tlValue2=0;
        this.cookieYPadd=0;
        this.tl1 = {
            value: this.tlValue1
        };
        this.tl2 = {
            value: this.tlValue2
        };
    }
    onEvents(data){

        switch(data['message']['event_type']){
            case 'create_scene':
                this.addCookie();
                break;
            case "update":
                this.update(data['message']['data']);
                break;
            case "update_cookie_position":
                this.updateAnimations(data['message']['data']['value1'], data['message']['data']['value2']);
                break;
        }
    }
    addCookie(){
        this.cookie= Global.assets['glbs']['cookie']['asset'].scene;
        this.scene.add(this.cookie);
        
        this.replaceMaterialsWithMeshBasicMaterial(this.cookie);

        this.cookie.scale.set(0.05, 0.05, 0.05)
        this.cookie.position.set(-.05,-.01,-.4)
  
        let cookiePos= {
            x: 0, 
            y: this.cookie.position.y,
            z: 0
        };

        gsap.to(cookiePos, {
            y: '+=.0025',
            duration: 3,
            ease: "cubic.inOut",
            yoyo: true,
            repeat:-1,
            onUpdate: function(){
                this.cookie.position.y=cookiePos.y - this.cookieYPadd;
            }.bind(this)
        });
        gsap.to(this.cookie.rotation, {
            z: '+=.1',
            ease: "cubic.inOut",
            duration: 3,
            yoyo: true,
            repeat:-1
        });
       //-.01
          
       
   
    }
    // this.updateAnimations(0)
   
    updateAnimations(value1, value2) {
        this.tlValue1= value1;
        this.tlValue2= Math.min(1, value2);

        gsap.killTweensOf(this.tl1);
        gsap.killTweensOf(this.tl2);
    

        if(value1 >= 1 && value2 > 0){
            gsap.to(this.tl2, {
                value: this.tlValue2,
                ease: "cubic.out",
                duration: 0.4,
                yoyo: false,
                delay:0,
                onUpdate: () => {
                    const startPosX = new THREE.Vector3(.05, 0, 0);
                    const endPosX = new THREE.Vector3(0, 0, 0);
    
                    const startPosZ = new THREE.Vector3(0, 0, -0.5);
                    const endPosZ = new THREE.Vector3(0, 0, -.2);
    
                    const startRotateVal = new THREE.Vector3(1.67, -0.2, 0);
                    const endRotateVal = new THREE.Vector3(0.67, -0.8, 0);
                    const currentRotateVal= new THREE.Vector3(0,0,0).lerpVectors(startRotateVal, endRotateVal, this.tl2.value*1.1);
                    const moveLerpVal= new THREE.Vector3(0,0,0).lerpVectors(new THREE.Vector3(0,0,0), new THREE.Vector3(Math.max(0, this.tl2.value*1.0-0.0),0,0), this.tl2.value);
                    
                    this.cookieYPadd=currentRotateVal.y*(23/window.innerHeight/* .03 */)
                    this.cookie.position.set(
                        new THREE.Vector3(0,0,0).lerpVectors(startPosX, endPosX, moveLerpVal.x).x,
                        this.cookie.position.y,
                        new THREE.Vector3(0,0,0).lerpVectors(startPosZ, endPosZ, this.tl2.value).z
                    )
                    this.cookie.rotation.set(
                        currentRotateVal.x,
                        currentRotateVal.y,
                        this.cookie.rotation.z
                    )
                }
            });
        }else{
            gsap.to(this.tl1, {
                value: this.tlValue1,
                ease: "cubic.out",
                duration: 0.4,
                yoyo: false,
                delay:0,
                onUpdate: () => {
                    if(this.tlValue2 <= 0){
                        this.cookieYPadd=0;
                    }
                    const startPosX = new THREE.Vector3(-.05, 0, 0);
                    const endPosX = new THREE.Vector3(.05, 0, 0);
    
                    const startPosZ = new THREE.Vector3(0, 0, -.4);
                    const endPosZ = new THREE.Vector3(0, 0, -.1);
    
                    const startRotateVal = new THREE.Vector3(0, 0, 0);
                    const endRotateVal = new THREE.Vector3(1.67, -0.8, 0);
                    const currentRotateVal= new THREE.Vector3(0,0,0).lerpVectors(startRotateVal, endRotateVal, this.tl1.value*1.1);
                    const moveLerpVal= new THREE.Vector3(0,0,0).lerpVectors(new THREE.Vector3(0,0,0), new THREE.Vector3(Math.max(0, this.tl1.value*1.1-0.4),0,0), this.tl1.value);
                    this.cookie.position.set(
                        new THREE.Vector3(0,0,0).lerpVectors(startPosX, endPosX, moveLerpVal.x).x,
                        this.cookie.position.y-this.cookieYPadd,
                        new THREE.Vector3(0,0,0).lerpVectors(startPosZ, endPosZ, this.tl1.value).z
                    )
                    this.cookie.rotation.set(
                        currentRotateVal.x,
                        currentRotateVal.y,
                        this.cookie.rotation.z
                    )
                }
            });
        }
        
      }
    replaceMaterialsWithMeshBasicMaterial(object) {
        // Traverse through the object to get all materials
        object.traverse(function(child) {
            if (child.isMesh) {
                // Loop through each material of the mesh
                child.material = Array.isArray(child.material) 
                    ? child.material.map(mat => {
                        // Preserve the existing texture and replace with MeshBasicMaterial
                        const newMat = new THREE.MeshBasicMaterial({
                            map: mat.map,  // Preserve the texture map
                            color: mat.color,  // Preserve the color
                            // wireframe: true,
                            side: THREE.DoubleSide
                            // Add other properties as needed
                        });
                        return newMat;
                    })
                    : new THREE.MeshBasicMaterial({
                        map: child.material.map,  // Preserve the texture map
                        color: child.material.color,  // Preserve the color
                        // wireframe: true,
                        side: THREE.DoubleSide
                        // Add other properties as needed
                    });
            }
        });
    }
    update(data){
        // this.cookie && (this.cookie.rotation.z +=.03);
    }

}